var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { KosekiDataService } from "~/services/repair-service/koseki-data.service";
import clone from "clone";
var EditDetail = /** @class */ (function (_super) {
    __extends(EditDetail, _super);
    function EditDetail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kosekiModel = {
            id: '',
            name: "",
            idNo: "",
            bank: "",
            kosekiArea: "",
            koseki: "",
            applyTransferArea: "",
            remark: ""
        };
        _this.loading = {
            state: false
        };
        _this.rules = {
            name: [
                { required: true, message: '请输入姓名', trigger: 'blur' }
            ],
            idNo: [
                { required: true, message: '请输入身份证号', trigger: 'blur' },
            ]
        };
        return _this;
    }
    EditDetail.prototype.success = function () {
        this.close();
    };
    EditDetail.prototype.close = function () {
        this.reset();
    };
    // 保存户籍信息
    EditDetail.prototype.save = function () {
        var _this = this;
        this.loading.state = true;
        var editform = this.$refs["edit-form"];
        editform.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.kosekiDataService
                .update(_this.kosekiModel, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('修改成功!');
                _this.success();
            }, function (_a) {
                var msg = _a.msg;
                _this.$message.error(msg);
            });
        });
    };
    /**
     * keep-alive生命周期钩子函数
     */
    EditDetail.prototype.activated = function () {
        // 加载数据
    };
    EditDetail.prototype.refresh = function (obj) {
        this.kosekiModel = clone(obj);
    };
    EditDetail.prototype.reset = function () {
        var add = this.$refs['edit-form'];
        add.resetFields();
    };
    __decorate([
        Dependencies(KosekiDataService)
    ], EditDetail.prototype, "kosekiDataService", void 0);
    __decorate([
        Emit("success")
    ], EditDetail.prototype, "success", null);
    __decorate([
        Emit("close")
    ], EditDetail.prototype, "close", null);
    EditDetail = __decorate([
        Component({
            components: {}
        })
    ], EditDetail);
    return EditDetail;
}(Vue));
export default EditDetail;
